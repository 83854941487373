<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field label="Заголовок" :value="tableParams.title" clearable @input="setField('title', $event)" />
      </v-col>

      <v-col cols="12" md="4">
        <date-range-picker v-model="publishedOn" label="Дата статьи" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  computed: {
    publishedOn: {
      get() {
        let date = {};

        if (this.tableParams.date) {
          if (this.tableParams.date?.from) {
            date.min = this.tableParams.date["from"];
          }

          if (this.tableParams.date?.to) {
            date = { min: this.tableParams.date["from"], max: this.tableParams.date["to"] };
          }
        }

        return date;
      },

      set(value) {
        const date = {};

        if (value) {
          if (value.min) {
            date.from = value.min;
          }

          if (value.max) {
            date.to = value.max;
          }
        }

        this.setField("date", date);
      }
    }
  }
};
</script>
